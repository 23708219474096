$primary: #2798B4

@font-face 
  font-family: 'mainFont'
  src: url("./../public/fonts/Raleway-VariableFont_wght.ttf")


@import 'bootstrap/dist/css/bootstrap.min.css'

html, body
  padding: 0
  margin: 0
  font-family: 'mainFont'

a
  text-decoration: none
  color: inherit
  &:hover
    color: $primary
  h2
    color: #222

.thin
  font-weight: 200 !important

.main-container
  display: flex
  top: 0
  width: 100vw
  height: 100vh
  max-width: 1200px
  &.contact
    // position: fixed
    flex-direction: column
    // justify-content: space-evenly
    padding: 1em 5em
    background-image: url(./../public/contact-bg.png)
    background-size: cover
    max-width: 100vw
  h1, h2
    letter-spacing: -2px
    font-weight: bolder
    span
      color: $primary
  h2
    font-size: 1.5em
    letter-spacing: -1px
    &.sections
      width: 100%
    
.images-container
  background-image: url(./../public/img-triple.png)
  background-size: cover
  position: fixed
  right: 0
  width: 30%
  height: 100vh

.home-nav
  a, span
    font-size: 2.5em
    font-weight: lighter
  a
    display: flex
    align-items: center
    height: 1.8em
    &:hover
      color: $primary
  span
    margin-right: 10px

.contact-call
  color: $primary
  font-weight: 700
  font-size: 1.2em
  line-height: 120%
  svg
    width: 2em
    height: 2em
    margin-top: 0.5em

.social-row
  font-size: 1.4em
  font-weight: 300
  margin-bottom: 0.8em
  span
    margin-left: 1em

.address
  font-size: 1.4em
  h3
    font-size: 0.8em
    margin: 0
    font-weight: 800
  p
    line-height: 105%
    font-weight: 300
  
footer
  position: relative
  bottom: 0
  display: flex
  justify-content: space-evenly
  align-items: center
  width: 100%
  background: $primary
  color: #fff
  font-size: 0.8em
  p
    margin: 0

.logo-container
  width: 60%
  margin-left: 20%
  img
    width: 100%
  &.contact
    width: 50%
    margin-left: 0