
$small: 768px

@media screen and (max-width: $small) 

  .main-container
    flex-direction: column

  .images-container
    width: 100%
  
  .social-row
    font-size: 1.3em
  
  .address
    margin-top: 3em
  
  .logo-container
    width: 80%
    margin-left: 0
    &.contact
      width: 80%

  footer
    flex-direction: column
    p
      width: 100%
      text-align: center
      margin: 0.5em 0